import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";

import SideBar from "features/side-bar";
import Checkbox from "application/components/CheckBox";
import PrimaryButton from "application/components/PrimaryButton";

import {
  AuthText,
  AuthWrapper,
  AuthContainer,
  AuthBottomWrapper,
  MobileConfirmButton,
} from "../../styles";
import {
  BoldText,
  CompleteTermsTextLink,
  TermsContainer,
  TermsText,
  TermsTitle,
} from "./styles";
import {
  SignUpTitle,
  SignUpContent,
  AgreeContainer,
  CheckBoxContainer,
} from "../styles";
import { useMediaQuery, useTheme } from "@mui/material";
import MobileFooter from "../../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../../application/components/MobileCopyright";
import arrowIcon from "application/assets/images/icons/arrow-white-small.svg";

const Terms = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  useEffect(() => {
    const subscriptionInfo = window.sessionStorage.getItem("subscription");

    if (!subscriptionInfo) {
      navigate(PathNames.registration);
    }

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 300);
  }, []);

  const toggleTerms = () => {
    if (!terms) {
      setTermsError(false);
    }
    setTerms(!terms);
  };

  const handleSubmit = () => {
    if (!terms) {
      setTermsError(true);
      return;
    }
    navigate(PathNames.registrationPayment);
  };

  return (
    <AuthWrapper>
      <SideBar type="sign-up" activeStep={4} />
      <AuthContainer isSignUp>
        <SignUpContent>
          <SignUpTitle>Terms and Conditions</SignUpTitle>
          <TermsContainer>
            <TermsTitle>Asset Camp Terms and Conditions</TermsTitle>
            <TermsText>
              The tools, charts, reports and other educational materials
              contained or displayed on Asset Camp are proprietary to Darby
              Creek Advisors LLC dba Money for the Rest of Us and Asset Camp
              (“Darby Creek Advisors”) and constitute valuable intellectual
              property. The tools, charts, reports, videos, and other materials
              may not be copied, photographed, recorded, downloaded, reproduced,
              or in any other way used or otherwise disseminated in any form to
              any person or entity.
              <br />
              <br />
              Asset Camp contains information (the “Information”) sourced from
              MSCI Inc., its affiliates or information providers (the “MSCI
              Parties”) and BLOOMBERG®.
              <br />
              <br />
              BLOOMBERG® is a trademark and service mark of Bloomberg Finance
              L.P. and its affiliates (collectively “Bloomberg”).
              <br />
              <br />
              The Information may have been used to calculate scores, ratings or
              other indicators. The Information is for internal use only, and
              may not be reproduced/redisseminated in any form, or used as a
              basis for or a component of any financial instruments or products
              or indices.
              <br />
              <br />
              The MSCI Parties, Bloomberg, and Darby Creek Advisors do not
              warrant or guarantee the originality, accuracy and/or completeness
              of any data or Information herein and expressly disclaim all
              express or implied warranties, including of merchantability and
              fitness for a particular purpose.
              <br />
              <br />
              The Information is not intended to constitute investment advice or
              a recommendation to make (or refrain from making) any investment
              decision and may not be relied on as such, nor should it be taken
              as an indication or guarantee of any future performance, analysis,
              forecast, or prediction.
              <br />
              <br />
              None of the MSCI Parties, Bloomberg, nor Darby Creek Advisors
              shall have any liability for any errors or omissions in connection
              with any data or Information herein, or any liability for any
              direct, indirect, special, punitive, consequential or any other
              damages (including lost profits) even if notified of the
              possibility of such damages.
              <br />
              <br />
              Bloomberg or Bloomberg’s licensors own all proprietary rights in
              the Bloomberg Indices. Neither Bloomberg nor Bloomberg’s licensors
              approves or endorses this material, or guarantees the accuracy or
              completeness of any information herein, or makes any warranty,
              express or implied, as to the results to be obtained therefrom
              and, to the maximum extent allowed by law, neither shall have any
              liability or responsibility for injury or damages arising in
              connection therewith.
              <br />
              <br />
              (ii) Licensee shall not use or distribute Data or Derived Data in
              any manner that, in Licensor’s good faith judgment, adversely
              affects Bloomberg’s (or any of its Data Providers’) ability to
              license the Data or would cause the use and distributions thereof
              to be a functional source of, or a substitute for, data otherwise
              commercially available from Bloomberg or any of its Data
              Providers.
              <br />
              <br />
              <BoldText>Asset Camp 7-Day Free Trial</BoldText>
              <br />
              Asset Camp, by default, offers a free 7-day trial of Asset Camp’s
              full set of tools, reports, webinars, and educational content to
              all new accounts.
              <br />
              <br />
              Your free trial lasts 7 days from, and will begin immediately
              after, you finish confirming the creation of your new Asset Camp
              account.
              <br />
              <br />
              Unless you cancel your trial/account, your annual subscription
              will begin automatically at the conclusion of the trial, and you
              will be charged the annual subscription price (minus any applied
              promotional discounts).
              <br />
              <br />
              You may cancel your trial/account at any time by visiting your
              account page at app.assetcamp.com.
              <br />
              <br />
              Along with a confirmation from Asset Camp, you will be sent a
              notification and receipt of your free trial from Stripe, our
              payment processor, regarding the start and billing details of your
              trial.
              <br />
              <br />
              Asset Camp will send you an email one day before the end of your
              free trial reminding you that your subscription is about to begin
              and that your payment method will be charged unless you cancel.
              <br />
              <br />
              We ask for your credit card information as part of our free trials
              in order to confirm trial eligibility. Card information helps us
              limit trials to one per person. Returning users are not eligible
              for free trials and will be charged at checkout.
              <br />
              <br />
              Once a credit card number has been used to access a free trial
              account, that credit card is not eligible for future free trials
              of Asset Camp. If the same card is used a second time, it will be
              billed immediately without a free trial upon signup completion.
              Additional trial periods may be granted to users at Asset Camp’s
              discretion.
              <br />
              <br />
              Asset Camp does not offer refunds for Asset Camp subscriptions,
              but if you feel your subscription began without sufficient
              notification or in error, please reach out to Asset Camp directly
              within 10 business days of the charge posting to your payment
              method and we will work with you to address the situation.
              <br />
              <br />
              If possible, please reach out to Asset Camp directly before
              issuing a chargeback or dispute with your card company or bank.
              Asset Camp will of course work with any issued disputes or
              chargebacks, but corrections to your account billing can be
              handled much faster if you contact Asset Camp directly first.
              Transparency, privacy, and honesty are important to us and how we
              handle your payments.
              <br />
              <br />
              For billing, account, or free trial questions or issues, please
              contact us at{" "}
              <a href="mailto:help@assetcamp.com">help@assetcamp.com</a>.
            </TermsText>
          </TermsContainer>

          <CompleteTermsTextLink>
            Before agreeing, please review our complete{" "}
            <a href="https://assetcamp.com/terms-and-policies/" target="_blank">
              Terms, Conditions, Disclosures, and Privacy Policy
            </a>
          </CompleteTermsTextLink>

          {!isMobile && (
            <AgreeContainer>
              <Checkbox
                checked={terms}
                error={termsError}
                onClick={toggleTerms}
              />
              <AuthText>
                I have read and agree to the Terms and Conditions of Asset Camp
              </AuthText>
            </AgreeContainer>
          )}

          {!isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                btn_type="light"
                title="Back"
                onClick={() => navigate(-1)}
              />
              <PrimaryButton title="Continue" onClick={handleSubmit} />
            </AuthBottomWrapper>
          )}
        </SignUpContent>
        {isTablet && (
          <MobileFooter>
            {isMobile && (
              <AgreeContainer>
                <CheckBoxContainer
                  checked={terms}
                  error={termsError}
                  onClick={toggleTerms}
                >
                  {terms && <img src={arrowIcon} alt="arrow" />}
                </CheckBoxContainer>
                <AuthText>
                  I have read and agree to the Terms and Conditions of Asset
                  Camp
                </AuthText>
              </AgreeContainer>
            )}
            {isMobile && (
              <MobileConfirmButton>
                <AuthBottomWrapper>
                  <PrimaryButton
                    btn_type="light"
                    title="Back"
                    onClick={() => navigate(-1)}
                  />
                  <PrimaryButton title="Continue" onClick={handleSubmit} />
                </AuthBottomWrapper>
              </MobileConfirmButton>
            )}
            <MobileCopyrightBlock />
          </MobileFooter>
        )}
      </AuthContainer>
    </AuthWrapper>
  );
};

export default Terms;
