import React, { useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import { reset, yieldCurveSelector } from "./store/slice";
import {
  Title,
  Container,
  TitleWrapper,
  CompareButton,
  TableInfoWrapper,
  BottomLogo,
  UnderContentInfo,
  ArrowBackContainer,
  ArrowBack,
  BottomMfruLogo,
  LogoWrapper,
  SubTitle,
  OpenChartIcon,
} from "./styles";
import { Loader } from "../../features/chart/styles";
import SmallLoader from "../../application/components/SmallLoader";
import MainContainer from "../../application/components/MainContainer";
import {
  Data,
  NoData,
} from "../../application/components/CurveSpreadSummaryTable/types";
import { find, propEq } from "ramda";
import logoBlueSource from "../../application/assets/images/logo-blue.svg";
import { applicationConfig } from "../../application/config/applicationConfig";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";
import LastUpdateDate from "../../application/components/LastUpdateDate";
import NotifyPopUpTable from "../../application/components/NotifyPopUpTable";
import { useMediaQuery, useTheme } from "@mui/material";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";
import OptionalTooltip from "application/components/OptionalTooltip";
import CurveSpreadSummaryTable from "application/components/CurveSpreadSummaryTable";
import BondYieldCurveActions from "./store/actions";
import YieldCurveChartModal from "application/components/YieldCurveChartModal";
import BloombergInfo from "application/components/BloombergInfo";
import { formatDate } from "application/helpers/dateFormatter";

const OpenChartAction = ({
  onClickHandler,
}: {
  onClickHandler: () => void;
}) => {
  return <OpenChartIcon onClick={onClickHandler} />;
};

const CurveSpreadSummary = () => {
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [compare, setCompare] = React.useState<boolean>(false);
  const [isBloombergVisible, setIsBloombergVisible] =
    React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const [date, setDate] = useState<string>("");
  const buttonSectionRef = useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] = useState<number>(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { filters, tableData, selectedMetric } =
    useAppSelector(yieldCurveSelector);

  const [selectedIndex, setSelectedIndex] = useState<{
    code: string;
    name: string;
    description?: string;
  }>();

  const openChart = (currIndex: {
    code: string;
    name: string;
    description?: string;
  }) => setSelectedIndex(currIndex);
  const closeChart = () => setSelectedIndex(undefined);

  const tableRowsData = useMemo(() => {
    if (tableData?.length) {
      return tableData.map((d: any) => {
        const indexObj = {
          code: `${d.indexCodeA}+${d.indexCodeB}`,
          name: d.indexPairName,
          description: d.description,
        };

        return {
          ...d,
          action: (
            <OpenChartAction
              onClickHandler={() => {
                openChart(indexObj);
              }}
            />
          ),
        };
      });
    }

    return tableData;
  }, [tableData]);

  const loading = requestData.getYieldCurveTableData.loading;
  useEffect(() => {
    Promise.all([dispatch(BondYieldCurveActions.getFilters())]).then(() => {
      dispatch(BondYieldCurveActions.getYieldCurveTableData());
    });

    if (!date.length) {
      Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
        setDate(data[0]?.lastUpdatedDate || "");
      });
    }

    if (buttonSectionRef.current) {
      setButtonSectionHeight(buttonSectionRef.current.clientHeight);
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (selectedMetric.code) {
      dispatch(BondYieldCurveActions.getYieldCurveTableData());
    }
  }, [filters]);

  useEffect(() => {
    let arr: Data[] = [];
    selectedCompare.map((item: Data) => {
      // @ts-ignore
      let dataItem: Data | NoData = find(propEq("indexPairName", item.indexPairName))(
        tableData.stockSummaryData
      );

      if (!dataItem) {
        dataItem = {
          ...item,
          noData: true,
        };
      } else {
        dataItem = {
          ...dataItem,
          action: item.action,
        };
      }

      return (arr = [...arr, dataItem]);
    });

    setSelectedCompare(arr);
  }, [tableData]);

  const headCells: any[] = [
    {
      id: "indexPairName",
      label: "Index Pairing",
      diffBackground: false,
    },
    {
      id: "currentValue",
      label: `As of ${
        tableData?.length
          ? formatDate(tableData[0]?.priorYearLastUpdateDate)
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "previousMonthValue",
      label: `As of ${
        tableData?.length
          ? formatDate(tableData[0]?.previousMonthValueDate)
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "priorYearLastUpdateValue",
      label: `As of ${
        tableData?.length
          ? formatDate(tableData[0]?.currentValueDate)
          : ""
      }`,
      diffBackground: false,
    },
    {
      id: "longTermAverage",
      label: "Long Term Average",
      diffBackground: false,
      tooltip: "The average yield curve spread since its starting year."
    },
    {
      id: "standardDeviation",
      label: `Standard Deviation`,
      diffBackground: false,
      tooltip: "A statistical measure of how far the most recent yield curve spread is from the long-term average, expressed in standard deviations. Positive for an above-average yield curve spread, negative for a below-average yield curve spread. Larger deviations (up or down) indicate rarer extremes."
    },
    {
      id: "longTermAverageStartingYear",
      label: `Long Term Average Starting Year`,
      diffBackground: false,
      tooltip: "The first year the yield curve spread became available."
    },
    {
      id: "action",
      label: "View Chart",
      diffBackground: false,
    },
  ];

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const toggleBloombergVisible = () => {
    setIsBloombergVisible(!isBloombergVisible);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  return (
    <div>
      <MainContainer>
        <Container>
          <TitleWrapper>
            <Title>Yield Curve Spreads Summary Table</Title>
            <LastUpdateDate date={date} />
          </TitleWrapper>
          {loading && (
            <Loader>
              <SmallLoader />
            </Loader>
          )}
          {!loading && (
            <div>
              <TableInfoWrapper ref={buttonSectionRef} compare={compare}>
                <OptionalTooltip text={selectedMetric.description}>
                  <SubTitle>{selectedMetric.name}</SubTitle>
                </OptionalTooltip>

                {!compare && (
                  <CompareButton
                    onClick={compareLines}
                    disabled={selectedCount < 2}
                  >
                    COMPARE {isMobile ? "" : " INDEXES"}
                  </CompareButton>
                )}
                {compare && (
                  <>
                    <CompareButton compare={compare} onClick={compareLines}>
                      back to full list
                    </CompareButton>
                    <ArrowBackContainer onClick={compareLines}>
                      <ArrowBack />
                    </ArrowBackContainer>
                  </>
                )}
              </TableInfoWrapper>
              {tableData?.length && (
                <CurveSpreadSummaryTable
                  historicalStock={tableRowsData}
                  showButton={showButton}
                  compareLines={compare}
                  headCells={headCells}
                  onSelectedCompare={handleSelectedCompare}
                  selectedCompare={selectedCompare}
                  buttonSection={buttonSectionHeight}
                  valuationSummary={true}
                />
              )}
              <UnderContentInfo>
                <BloombergInfo
                  isBloombergVisible={isBloombergVisible}
                  underTable
                  onChange={toggleBloombergVisible}
                />
                <LogoWrapper>
                  <BottomMfruLogo
                    src={mfruLogoSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.main_page, "_blank");
                    }}
                  />
                  <BottomLogo
                    src={logoBlueSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.assetcamp, "_blank");
                    }}
                  />
                </LogoWrapper>
              </UnderContentInfo>
            </div>
          )}
        </Container>
        {isTablet && <NotifyPopUpTable />}
      </MainContainer>
      {selectedIndex && (
        <YieldCurveChartModal
          onClose={closeChart}
          selectedIndex={selectedIndex}
          selectedMetric={selectedMetric}
        />
      )}
    </div>
  );
};

export default CurveSpreadSummary;
