import React, { useEffect, useState } from "react";
import MainContainer from "../../application/components/MainContainer";
import ReportLastUpdateAction from "./actions/ReportLastUpdateAction";
import SmallLoader from "../../application/components/SmallLoader";
import SmallLoaderWrapper from "../../application/components/SmallLoaderWrapper";
import {
  HowToCard,
  HowToCardText,
  TextWrapper,
  HowToLogo,
  HowToButton,
  ContainerStyled,
  TabsSectionStyled,
  ButtonStyled,
  StockIndexesStyled,
  StockSection,
  StockSectionTitle,
  Cards,
  CardIcon,
  CardDescription,
  CardStyled,
  StockSectionTitleDivider,
  HowToTitle,
  HowToText,
} from "./styles";
import exampleFundsIcon from "../../application/assets/images/homeIcons/example-funds.svg";
import expectedReturnsIcon from "application/assets/images/homeIcons/expected-returns.svg";
import performanceAttributionIcon from "application/assets/images/homeIcons/performance-attribution.svg";
import performanceComparisonIcon from "application/assets/images/homeIcons/performance-comparison.svg";
import returnSummaryTableIcon from "application/assets/images/homeIcons/return-summary-table.svg";
import valuationChartsIcon from "application/assets/images/homeIcons/valuations-charts.svg";
import valuationTablesIcon from "application/assets/images/homeIcons/valuations-tables.svg";
import howToIcon from "application/assets/images/homeIcons/howToIcon.svg";
import spreadsSummaryTableIcon from "application/assets/images/homeIcons/yield-curve-spreads-summary-table.svg";
import spreadComparisonIcon from "application/assets/images/homeIcons/spread-comparison.svg";
import PrimaryButton from "../../application/components/PrimaryButton";
import { applicationConfig } from "../../application/config/applicationConfig";
import { ReactComponent as StockIcon } from "application/assets/images/homeIcons/stock-icon.svg";
import { ReactComponent as BondIcon } from "application/assets/images/homeIcons/bond-icon.svg";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";

interface StockSectionProps {
  title: string;
  cards: { icon: string; description: string; onClickHandler: () => void }[];
}


const BondMarket = () => {
  const navigate = useNavigate();
  const navigateTo = (path: PathNames) => navigate(path);
  const bondSections: StockSectionProps[] = [
    {
      title: "What Happened?",
      cards: [
        {
          icon: returnSummaryTableIcon,
          description: "Bond Market Returns Summary Table",
          onClickHandler: () => navigateTo(PathNames.bondIndicesSummary),
        },
        {
          icon: performanceAttributionIcon,
          description: "Bond Market Performance Comparison Tool",
          onClickHandler: () => navigateTo(PathNames.bondsComparison),
        },
      ],
    },
    {
      title: "Where Are We Now?",
      cards: [
        {
          icon: valuationTablesIcon,
          description: "Bond Summary Tables",
          onClickHandler: () => navigateTo(PathNames.bondValuationSummary),
        },
        {
          icon: valuationChartsIcon,
          description: "Bond Charts",
          onClickHandler: () => navigateTo(PathNames.fixedIncomeCharts),
        },
        {
          icon: spreadsSummaryTableIcon,
          description: "Yield Curve Spreads Summary Table",
          onClickHandler: () => navigateTo(PathNames.curveSpreadSummary),
        },
        {
          icon: spreadComparisonIcon,
          description: "Stock Earnings Yields and Bond Yields Spread Comparison",
          onClickHandler: () => navigateTo(PathNames.stockBondComparison),
        },
      ],
    },
    {
      title: "Where Are We Heading?",
      cards: [
        {
          icon: expectedReturnsIcon,
          description: "Bond Market Expected Returns Modeling",
          onClickHandler: () => navigateTo(PathNames.expectedReturnsModeling),
        },
      ],
    },
    {
      title: "Take Action",
      cards: [
        {
          icon: exampleFundsIcon,
          description: "Example Bond ETF and Index Funds",
          onClickHandler: () => {window.open(applicationConfig.bond_funds, "_blank")},
        },
      ],
    },
  ];
  return (
    <StockIndexesStyled>
      {bondSections.map((section, idx) => (
        <Bond key={idx} stock={section} />
      ))}
    </StockIndexesStyled>
  );
};

const Bond = ({ stock }: { stock: StockSectionProps }) => {
  const dividerWidth =
  stock.cards.length <= 3
  ? `${356 * stock.cards.length + 36 * (stock.cards.length - 1)}px`
  : "100%";

  return (
    <StockSection>
      <StockSectionTitle>
        <p>{stock.title}</p>
        <StockSectionTitleDivider
          width={dividerWidth}
          cardsCount={stock.cards.length}
        />
      </StockSectionTitle>
      <Cards>
        {stock.cards.map((card, idx) => (
          <CardStyled key={idx} onClick={card.onClickHandler}>
            <CardIcon src={card.icon} />
            <CardDescription>{card.description}</CardDescription>
          </CardStyled>
        ))}
      </Cards>
    </StockSection>
  );
};

const Stock = ({ stock }: { stock: StockSectionProps }) => {
  const dividerWidth =
    stock.cards.length <= 3
      ? `${356 * stock.cards.length + 36 * (stock.cards.length - 1)}px`
      : "100%";

  return (
    <StockSection>
      <StockSectionTitle>
        <p>{stock.title}</p>
        <StockSectionTitleDivider
          width={dividerWidth}
          cardsCount={stock.cards.length}
        />
      </StockSectionTitle>
      <Cards>
        {stock.cards.map((card, idx) => (
          <CardStyled key={idx} onClick={card.onClickHandler}>
            <CardIcon src={card.icon} />
            <CardDescription>{card.description}</CardDescription>
          </CardStyled>
        ))}
      </Cards>
    </StockSection>
  );
};

const StockMarket = () => {
  const navigate = useNavigate();
  const navigateTo = (path: PathNames) => navigate(path);

  const stockSections: StockSectionProps[] = [
    {
      title: "What Happened?",
      cards: [
        {
          icon: returnSummaryTableIcon,
          description: "Stock Market Returns Summary Table",
          onClickHandler: () => navigateTo(PathNames.indicesSummary),
        },
        {
          icon: performanceAttributionIcon,
          description: "Stock Market 10-Year Performance Attribution",
          onClickHandler: () => navigateTo(PathNames.historicalStock),
        },
        {
          icon: performanceComparisonIcon,
          description: "Stock Market Performance Comparison Tool",
          onClickHandler: () => navigateTo(PathNames.indicesComparison),
        },
      ],
    },
    {
      title: "Where Are We Now?",
      cards: [
        {
          icon: valuationTablesIcon,
          description: "Stock Valuations and Earnings Summary Tables",
          onClickHandler: () => navigateTo(PathNames.valuationSummary),
        },
        {
          icon: valuationChartsIcon,
          description: "Stock Valuations, Earnings, and Trends Charts",
          onClickHandler: () => navigateTo(PathNames.valuationReports),
        },
      ],
    },
    {
      title: "Where Are We Heading?",
      cards: [
        {
          icon: expectedReturnsIcon,
          description: "Stock Market Expected Returns Modeling",
          onClickHandler: () => navigateTo(PathNames.expectStock),
        },
      ],
    },
    {
      title: "Take Action",
      cards: [
        {
          icon: exampleFundsIcon,
          description: "Example Stock ETF and Index Funds",
          onClickHandler: () =>
            window.open(applicationConfig.bond_funds, "_blank"),
        },
      ],
    },
  ];

  return (
    <StockIndexesStyled>
      {stockSections.map((section, idx) => (
        <Stock key={idx} stock={section} />
      ))}
    </StockIndexesStyled>
  );
};

const Home = () => {
  const [date, setDate] = useState<string>("");
  const [doStockMarketTabOpened, setDoStockMarketTabOpened] = useState(true);
  const switchTab = () => setDoStockMarketTabOpened((prev) => !prev);
  useEffect(() => {
    Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
      setDate(data[0]?.lastUpdatedDate || "");
    });
    window.sessionStorage.clear();
  }, []);

  const handleHowToLink = () => {
    window.open(applicationConfig.how_to_page, "_blank");
  };

  return (
    <ContainerStyled>
      <MainContainer date={date}>
        {!date && (
          <SmallLoaderWrapper>
            <SmallLoader />
          </SmallLoaderWrapper>
        )}
        {date && (
          <>
            <HowToCard>
              <TextWrapper>
                <HowToLogo src={howToIcon} alt="how to" />
                <HowToCardText>
                  <HowToTitle>Lessons and Tutorials</HowToTitle>
                  <HowToText>
                    Learn to use the tools and discover our proven investment
                    framework
                  </HowToText>
                </HowToCardText>
              </TextWrapper>

              <HowToButton>
                <PrimaryButton title="Start Here" onClick={handleHowToLink} />
              </HowToButton>
            </HowToCard>
            <TabsSectionStyled>
              <ButtonStyled
                isActive={doStockMarketTabOpened}
                onClick={() => !doStockMarketTabOpened && switchTab()}
              >
                <StockIcon />
                <p>STOCK MARKET</p>
              </ButtonStyled>
              <ButtonStyled
                isActive={!doStockMarketTabOpened}
                onClick={() => doStockMarketTabOpened && switchTab()}
              >
                <BondIcon />
                <p>BOND MARKET</p>
              </ButtonStyled>
            </TabsSectionStyled>
            {doStockMarketTabOpened ? <StockMarket /> : <BondMarket />}
          </>
        )}
      </MainContainer>
    </ContainerStyled>
  );
};

export default Home;
