import React, { useEffect, useMemo, useRef, useState } from "react";

import { FilterData, FilterDataItem } from "./store/model";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  reset,
  setStockBondYieldFilterMetric,
  stockBondYieldSummarySelector,
} from "./store/slice";
import DropGroup from "application/components/DropGroup";
import {
  Title,
  Container,
  DropContainer,
  FiltersContainer,
  TitleWrapper,
  CompareButton,
  TableInfoWrapper,
  BottomLogo,
  UnderContentInfo,
  ArrowBackContainer,
  ArrowBack,
  BottomMfruLogo,
  LogoWrapper,
  SubTitle,
  OpenChartIcon,
} from "./styles";
import { Loader } from "../../features/chart/styles";
import SmallLoader from "../../application/components/SmallLoader";
import MainContainer from "../../application/components/MainContainer";
import MSCIBloombegInfo from "../../application/components/MSCIBloombergInfo";
import { find, propEq } from "ramda";
import logoBlueSource from "../../application/assets/images/logo-blue.svg";
import { applicationConfig } from "../../application/config/applicationConfig";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";
import LastUpdateDate from "../../application/components/LastUpdateDate";
import NotifyPopUpTable from "../../application/components/NotifyPopUpTable";
import { useMediaQuery, useTheme } from "@mui/material";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";
import OptionalTooltip from "application/components/OptionalTooltip";
import StockBondComparisonTable from "application/components/StockBondComparisonTable";
import BondYieldSummary from "./store/actions";
import { Data } from "application/components/StockBondComparisonTable/types";
import StockBondYieldChartModal from "application/components/StockBondYieldChartModal";

const OpenChartAction = ({
  onClickHandler,
}: {
  onClickHandler: () => void;
}) => {
  return <OpenChartIcon onClick={onClickHandler} />;
};

const BondYieldsSpreadComparison = () => {
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [compare, setCompare] = React.useState<boolean>(false);
  const [isMSCIVisible, setIsMSCIVisible] = React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const [date, setDate] = useState<string>("");
  const buttonSectionRef = useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] = useState<number>(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { filters, tableData, metrics } = useAppSelector(
    stockBondYieldSummarySelector
  );

  const [selectedIndex, setSelectedIndex] = useState<{
    code: string;
    name: string;
    description?: string;
  }>();

  const openChart = (currIndex: {
    code: string;
    name: string;
    description?: string;
  }) => setSelectedIndex(currIndex);
  const closeChart = () => setSelectedIndex(undefined);

  const tableRowsData = useMemo(() => {
    if (tableData?.length) {
      const metricsItems: any = metrics;
      return tableData.map((e: any) => {
        const indexObj = {
          code: e.indicesPairId,
          name: metricsItems.bondStockIndicesPairs.filter(
            (elem: any) => elem.indicesPairId === e.indicesPairId
          )[0].name,
          description: e.description,
        };
        return {
          ...e,
          indexPairName: metricsItems.bondStockIndicesPairs.filter(
            (elem: any) => elem.indicesPairId === e.indicesPairId
          )[0].name,
          action: (
            <OpenChartAction
              onClickHandler={() => {
                openChart(indexObj);
              }}
            />
          ),
          position: metricsItems.bondStockIndicesPairs.filter(
            (elem: any) => elem.indicesPairId === e.indicesPairId
          )[0].position,
        };
      });
    }
  }, [tableData]);

  const loading = requestData.getStockBondYieldTableData.loading;

  useEffect(() => {
    Promise.all([dispatch(BondYieldSummary.getMetrics())]).then((resArr) => {
      const metricRes = resArr[0].payload as FilterData;
      const metricKeys = Object.keys(metricRes);

      const initialMetric = metricRes[metricKeys[0]][0];

      handleMetricSelect(initialMetric);
    });

    if (!date.length) {
      Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
        setDate(data[0]?.lastUpdatedDate || "");
      });
    }

    if (buttonSectionRef.current) {
      setButtonSectionHeight(buttonSectionRef.current.clientHeight);
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (filters.selectedMetric.code) {
      dispatch(BondYieldSummary.getValuationSummaryTableData());
    }
  }, [filters]);

  useEffect(() => {
    let arr: Data[] = [];
    selectedCompare.map((item: Data) => {
      // @ts-ignore
      let dataItem: Data | NoData = find(propEq("indexPairName", item.indexPairName))(
        tableRowsData
      );

      if (!dataItem) {
        dataItem = {
          ...item,
          noData: true,
        };
      } else {
        dataItem = {
          ...dataItem,
          action: item.action,
        };
      }

      return (arr = [...arr, dataItem]);
    });

    setSelectedCompare(arr);
  }, [tableData]);

  const headCells: any[] = useMemo(() => {
    return [
      {
        id: "indexPairName",
        label: "Index Pair",
        diffBackground: false,
      },
      {
        id: "stockIndexValue",
        label: filters.selectedMetric.code === 'forward_earnings_yield' ? `Forward Earnings Yield` : `Earnings Yield` ,
        diffBackground: false,
        tooltip: "A measure of how much investors are paying for a dollar’s worth of current earnings expressed as a percentage yield. Earnings yield is the inverse of the price-to-earnings ratio (i.e., earnings yield = 1/price-to-earnings ratio). "
      },
      {
        id: "bondIndexValue",
        label: `Bond YTM`,
        diffBackground: false,
        tooltip: "The yield-to-maturity is the internal rate of return for the index if its underlying bonds are held until maturity or redeemed early. YTM is an estimate of the index’s return before accounting for interest rate changes or defaults.",
      },
      {
        id: "spread",
        label: `Spread`,
        diffBackground: false,
        tooltip: "The difference obtained by subtracting the bond yield-to-maturity from the stock earnings yield.",
      },
      {
        id: "spreadAverage",
        label: `Spread Average`,
        diffBackground: false,
        tooltip: "The average spread between stock earnings yield and bond yield-to-maturity since its starting year.",
      },
      {
        id: "spreadStandardDeviation",
        label: `Spread Standard Deviations`,
        diffBackground: false,
        tooltip: "A statistical measure of how far the most recent yield spread is from the long-term average, expressed in standard deviations. Positive for an above-average spread, negative for a below-average spread. Larger deviations (up or down) indicate rarer extremes.",
      },
      {
        id: "action",
        label: "View Chart",
        diffBackground: false,
      },
    ]
  }, [filters.selectedMetric.code]);

  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setStockBondYieldFilterMetric(item));
  };

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const toggleMSCIVisible = () => {
    setIsMSCIVisible(!isMSCIVisible);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  return (
    <div>
      <MainContainer>
        <Container>
          <TitleWrapper>
            <Title>
              Stock Earnings Yields and Bond Yields Spread Comparison
            </Title>
            <LastUpdateDate date={date} />
          </TitleWrapper>
          <FiltersContainer>
            <DropContainer>
              <DropGroup
                data={{"": metrics.stockComparisonMetrics}}
                label="Specific metric"
                disabled={loading}
                onSelect={handleMetricSelect}
                selected={filters.selectedMetric}
                placeholder="Specific metric name goes here"
              />
            </DropContainer>
          </FiltersContainer>
          {loading && (
            <Loader>
              <SmallLoader />
            </Loader>
          )}
          {!loading && (
            <div>
              <TableInfoWrapper ref={buttonSectionRef} compare={compare}>
                <OptionalTooltip text={filters.selectedMetric.description}>
                  <SubTitle>{filters.selectedMetric.name}</SubTitle>
                </OptionalTooltip>

                {!compare && (
                  <CompareButton
                    onClick={compareLines}
                    disabled={selectedCount < 2}
                  >
                    COMPARE {isMobile ? "" : " INDEXES"}
                  </CompareButton>
                )}
                {compare && (
                  <>
                    <CompareButton compare={compare} onClick={compareLines}>
                      back to full list
                    </CompareButton>
                    <ArrowBackContainer onClick={compareLines}>
                      <ArrowBack />
                    </ArrowBackContainer>
                  </>
                )}
              </TableInfoWrapper>
              {tableData?.length && (
                <StockBondComparisonTable
                  historicalStock={tableRowsData}
                  showButton={showButton}
                  compareLines={compare}
                  headCells={headCells}
                  onSelectedCompare={handleSelectedCompare}
                  selectedCompare={selectedCompare}
                  buttonSection={buttonSectionHeight}
                  valuationSummary={true}
                />
              )}
              <UnderContentInfo>
                <MSCIBloombegInfo
                  isMSCIVisible={isMSCIVisible}
                  underTable
                  onChange={toggleMSCIVisible}
                />
                <LogoWrapper>
                  <BottomMfruLogo
                    src={mfruLogoSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.main_page, "_blank");
                    }}
                  />
                  <BottomLogo
                    src={logoBlueSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.assetcamp, "_blank");
                    }}
                  />
                </LogoWrapper>
              </UnderContentInfo>
            </div>
          )}
        </Container>
        {isTablet && <NotifyPopUpTable />}
      </MainContainer>
      {selectedIndex && (
        <StockBondYieldChartModal
          onClose={closeChart}
          selectedIndex={selectedIndex}
          selectedMetric={filters.selectedMetric}
        />
      )}
    </div>
  );
};

export default BondYieldsSpreadComparison;
